import { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import { getChangeApprovers } from 'api'
import AutoCompleteComponent from 'components/steps/AutoCompleteComponent'
import { IEditData } from 'pages/StatusChangeDetail'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Employee } from 'types/Employee'
import { useDebounce } from 'use-debounce'

// eslint-disable-next-line import/no-default-export
export default function ChangeApprover({
  requestId,
  editData,
  setEditData,
}: {
  requestId: number | undefined
  editData?: IEditData | undefined
  setEditData?: (value) => void
}) {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 200)

  const { isLoading, isFetching, data } = useQuery(
    ['employees', requestId, debouncedSearch],
    () => getChangeApprovers(debouncedSearch, requestId)
  )

  useEffect(() => {
    return () => {
      setSearch('')
    }
  })

  const setData = (
    value: Employee | undefined,
    reason: AutocompleteChangeReason
  ) => {
    if (reason !== 'clear') {
      setEditData && setEditData({ ...editData, ...{ approver: value } })
    } else {
      setEditData && setEditData(undefined)
    }
  }

  return (
    <AutoCompleteComponent
      data={data}
      isFetching={isFetching}
      isLoading={isLoading}
      selectedEmployee={(editData && editData.approver) ?? null}
      setData={setData}
      setSearch={setSearch}
      label="Approver Name"
    />
  )
}
