import { Reason } from 'api'
import { BaseRequest } from 'types/BaseRequest'
import { BaseState } from 'types/BaseState'
import { Employee } from 'types/Employee'

export interface TerminationChangeRequest extends BaseRequest {
  employeeId: number
  effectiveDate: string
  lastDayWorked: string
  reasonCode: string
  workedDays: WorkedDay[]
  replacementEmployeeId?: number
}
export interface TerminationChangeState extends BaseState {
  effectiveDate: Date | null
  lastDayWorked: Date | null
  reasonCode?: Reason
  workedDays?: WorkedDay[]
  replacement?: Employee
}

export function readyToSubmit(data: TerminationChangeState) {
  return (
    typeof data.employee !== 'undefined' &&
    data.effectiveDate !== null &&
    data.reasonCode !== null &&
    (!data.employee.hasReportTos || typeof data.replacement !== 'undefined') &&
    (data.lastDayWorked != null || typeof data.workedDays !== 'undefined')
  )
}

export interface WorkedDay {
  date?: Date | null | string // string is for service
  regularHours?: number
  overTimeHours?: number
  isEnabled?: boolean
  isLastDay?: boolean
}
export interface GroupedWorkedDays {
  weekEndDate: Date | null
  workedDays: WorkedDay[]
  regularHoursSum: number
  otHoursSum: number
}
