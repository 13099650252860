import { POSJobCode, Reason } from 'api'
import {
  EmployeePendingActionsInAdp,
  ExistingJobFunction,
} from 'types/Employee'
import { Location } from 'types/Location'
import { RateUnit } from 'types/RateUnit'
import { GroupedWorkedDays, WorkedDay } from 'types/TerminationChangeRequest'
import {
  FinalCheckRule,
  HoursFromPos,
  VacationAndSickHour,
} from 'types/TerminationDetails'
export enum Status {
  Submitted = 'Submitted',
  PendingApprover1 = 'PendingApprover1',
  Approved1 = 'Approved1',
  PendingApprover2 = 'PendingApprover2',
  Approved2 = 'Approved2',
  PendingApprover3 = 'PendingApprover3',
  Approved3 = 'Approved3',
  SubmittedToADP = 'SubmittedToADP',
  ConfirmedByADP = 'ConfirmedByADP',
  ApprovedByHR = 'ApprovedByHR',
  PendingApprovalByHR = 'PendingApprovalByHR',
  PendingCompletionInADP = 'PendingCompletionInADP',
  CompletedInADP = 'CompletedInADP',
  SentToPOS = 'SentToPOS',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  CancelledBySystem = 'CancelledBySystem',
}
export enum EmployeeRecordRequestType {
  Rate = 'Rate',
  JobFunction = 'JobFunction',
  JobTitle = 'JobTitle',
  Borrow = 'Borrow',
  Transfer = 'Transfer',
  Termination = 'Termination',
  LeaveOfAbsence = 'LeaveOfAbsence',
  HourlySalary = 'HourlySalary',
  ReturnFromLeave = 'ReturnFromLeave',
  POSJobCode = 'POSJobCode',
  Rehire = 'Rehire',
}

export enum AdpChangeMessageType {
  Info = 0,
  Warning = 1,
  Error = 2,
}
export interface AdpChange {
  field: string
  oldValue: string
  newValue: string
  messageType: AdpChangeMessageType
}
export interface StatusChangeResponseBase {
  id: number
  status: Status
  effectiveDate: string // ISO date string
  timezone: string
  positionId: string
  location: Location
  notes: string
  employeeName: string
  requestorName: string
  type: string
  subType: string
  employeeId: number
  requestorId: number
  auditTrails: Array<{
    action: Status
    user: string
    date: string
    description: string
    oldValue: string
    newValue: string
  }>
  canApprove: boolean
  canComplete: boolean
  canCompleteManuallyInPOS: boolean
  canSaveToADP: boolean
  canCancel: boolean
  canEdit: boolean
  canChangeApprover: boolean
  rejectionReason: string
  friendlyStatus: string
  remainingWorkHours: number
  adpChanges: AdpChange[]
  pendingRequestsInAdpOnComplete: EmployeePendingActionsInAdp[]
  pendingRequestsInAdpOnSubmit: EmployeePendingActionsInAdp[]
  fileUploadEnabled: boolean
  saveToADPMessage?: string
  details: StatusChangeDetails
}

export interface StatusChangeDetails {
  reason?: Reason
  newAdpJobTitleCode: string
  newJobTitleDescription: string
  newRate: number
  newRateUnit: RateUnit.HOURLY | RateUnit.SALARY
  existingAdpJobTitleCode: string
  existingJobTitleDescription: string
  existingRate: number
  existingRateUnit: RateUnit.HOURLY | RateUnit.SALARY
  pendingActionByEmployeeName?: string
  pendingActionByEmployeeId?: number
  approverEmployeeName?: string
  approverEmployeeId?: number
  replacementEmployeeName?: string
  replacementEmployeeId?: number
  posJobCode: POSJobCode
}

export interface StatusChangeDetailsPOSJobCode extends StatusChangeDetails {
  posJobCode: POSJobCode
}

export interface StatusChangeDetailsBorrow extends StatusChangeDetails {
  fromLocation: Location
  borrowingLocation: Location
  endDate: string
}

export interface StatusChangeDetailsRehireReinstatement
  extends StatusChangeDetails {
  terminationDate: string
  terminationReasonCodeAndDesc: string
  isRehireable: boolean
  isReinstatement: boolean
  previousLocation: Location
  destinationLocation: Location
}

export interface StatusChangeDetailsTransfer extends StatusChangeDetails {
  transferToLocation: Location
}

export interface StatusChangeDetailsJobFunction extends StatusChangeDetails {
  // using existing type for both b/c at this point both lists have rates and
  // existing jf is the only type that has non-optional rate
  existingJobFunctions: ExistingJobFunction[]
  proposedJobFunctions: ExistingJobFunction[]
}

export interface StatusChangeDetailsLeaveOfAbsence extends StatusChangeDetails {
  returnDate: Date
  lastDayWorkedUtc: Date
  hasLeaveOfAbsenceDocument: boolean
}

export interface StatusChangeDetailsTermination extends StatusChangeDetails {
  remainingWorkHours: number
  finalCheckDeliveryTimeUtc: Date
  lastDayWorkedUtc: Date
  eligibleForRehire: boolean
  isVoluntary: boolean
  workedDays: WorkedDay[]
  isFinalCheck: boolean
  hoursFromPos: HoursFromPos[]
  finalCheckWorkedDays: GroupedWorkedDays[]
  mileageNotExportedTotal: number
  mileageExportedRecentlyTotal: number
  vacationAndSickHour: VacationAndSickHour
  finalCheckRules: FinalCheckRule[]
  paySelectionType: string
}
