import { CircularProgress } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import React from 'react'
import { Employee } from 'types/Employee'
import { APIResult } from '../../api'

// eslint-disable-next-line import/no-default-export
export default function AutoCompleteComponent({
  data,
  isFetching,
  isLoading,
  selectedEmployee,
  setData,
  setSearch,
  label,
}: {
  data: APIResult<Employee[]> | undefined
  isFetching: boolean
  isLoading: boolean
  selectedEmployee: Employee | undefined | null
  setData: (
    value: Employee | undefined,
    reason: AutocompleteChangeReason
  ) => void
  setSearch: React.Dispatch<React.SetStateAction<string>>
  label: string
}) {
  return (
    <>
      <Autocomplete
        fullWidth
        id="employee-select"
        data-testid="ee-select"
        options={data?.data || []}
        getOptionLabel={(opt: any) =>
          `${opt.employeeName} - ${opt.adpPositionId}`
        }
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        value={selectedEmployee ?? null}
        noOptionsText="Start typing to search..."
        onInputChange={(_, value) => setSearch(value)}
        onChange={(_, value, reason) => setData(value ?? undefined, reason)}
        loading={isLoading}
        autoHighlight={true}
        renderInput={(params) => (
          <TextField
            autoFocus
            {...params}
            label={`${label}`}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}
