import { Autocomplete, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getJobTitles, searchEmployee } from 'api'
import BlockExistingRequest from 'components/steps/BlockExistingRequest'
import {
  effectiveDateStep,
  getDefaultEffectiveDate,
} from 'components/steps/EffectiveDate'
import { locationStep } from 'components/steps/location/locationStep'

import { InputMask } from '@react-input/mask'
import StepperWorkflow from 'components/StepperWorkflow'
import { SpecifyRateAsk } from 'components/steps/SpecifyRateAsk'
import { SpecifyRateTypeAsk } from 'components/steps/SpecifyRateTypeAsk'
import { StepButtons } from 'components/steps/StepButtons'
import LegalLastName from 'components/steps/rehire-reinstatement/LegalLastName'
import RehireReinstatementEligibility from 'components/steps/rehire-reinstatement/RehireReinstatementEligibility'
import { reviewStep } from 'components/steps/review/reviewStep'
import { useUser } from 'context/Authenticate'
import { useClasses } from 'hooks/useClasses'
import {
  KeysToClear,
  useStateClearingReducer,
} from 'hooks/useStateClearingReducer'
import { useTitle } from 'hooks/useTitle'

import { REHIRE_PATH } from 'link-paths'
import { round } from 'lodash'
import { handleNextStep } from 'pages/Home'
import NoRoleAccess from 'pages/NoRoleAccess'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { theme, useContentStyle } from 'theme'
import { JobTitle } from 'types/Employee'
import { PositionStatus } from 'types/PositionStatus'
import { JobTitleRateUnit, RateUnit } from 'types/RateUnit'
import { RehireState } from 'types/RehireRequest'
import { EmployeeRecordRequestType } from 'types/StatusChangeResponse'
import { StepInfo } from 'types/StepProps'
import { convertRateToBiweeklyAmount } from 'utils'
import { MAXIMUM_NOTES_LENGTH } from '../components/steps/Notes'
const styles = {
  root: {
    minWidth: 450,
    minHeight: 200,
  },
}

const SelectLocation = React.lazy(
  () => import('components/steps/location/SelectDestinationLocation')
)

const SelectEmployee = React.lazy(
  () => import('components/steps/SelectEmployee')
)
const Notes = React.lazy(() => import('components/steps/Notes'))
const ReviewRehire = React.lazy(
  () => import('components/steps/review/ReviewRehireReinstatement')
)

const EffectiveDate = React.lazy(() => import('components/steps/EffectiveDate'))

const legalLastNameStep: StepInfo = {
  stepLabel: 'Legal Last Name',
  cardLabel:
    'Enter the legal last name of the employee exactly as it will appear on the I9 document.',
  url: 'legal-last-name',
}

const ssnStep: StepInfo = {
  stepLabel: 'SSN',
  cardLabel: "What is the applicant's SSN?",
  url: 'ssn',
}

const employeeStep: StepInfo = {
  stepLabel: 'Employee',
  cardLabel: 'Which employee are you rehiring?',
  url: 'employee',
}

const jobtTitleStep: StepInfo = {
  stepLabel: 'Job Title',
  cardLabel: 'Select new title',
  url: 'job-title',
}

const jobTitleRateTypeStep: StepInfo = {
  stepLabel: 'Rate of Pay Type',
  cardLabel: '',
  url: 'rate-type',
}

const jobTitleRateStep: StepInfo = {
  stepLabel: 'Rate of Pay',
  cardLabel: 'Select new rate of pay',
  url: 'rate',
}

export const reasonStep: StepInfo = {
  cardLabel: 'Reason',
  stepLabel: 'Reason',
  url: 'reason',
}

export const eligibilityStep: StepInfo = {
  cardLabel: 'Eligibility',
  stepLabel: 'Eligibility',
  url: 'eligibility',
}

type ConditionalSteps = (StepInfo | ((data: RehireState) => StepInfo | null))[]
// Starting set of steps (others are added conditionally based on data from employee details)
const stepsInit: ConditionalSteps = [
  legalLastNameStep,
  ssnStep,
  employeeStep,
  (data) => {
    const { employeeName = 'the employee' } = data.employee || {}
    return locationStep(
      `Which location is rehiring/reinstating ${employeeName}?`
    )
  },
  effectiveDateStep,
  eligibilityStep,
  jobtTitleStep,
  (data) =>
    data.newTitle?.jobTitleRateCode === JobTitleRateUnit.BOTH
      ? jobTitleRateTypeStep
      : null,
  jobTitleRateStep,
  reasonStep,
  reviewStep,
]

function calculateSteps(
  steps: ConditionalSteps,
  state: RehireState
): StepInfo[] {
  return steps.reduce((prev, curr) => {
    if (typeof curr === 'function') {
      const result = curr(state)
      if (result) {
        prev.push(result)
      }
    } else {
      prev.push(curr)
    }
    return prev
  }, [] as StepInfo[])
}

const initialState: RehireState = {
  employee: undefined,
  location: undefined,
  legalLastName: '',
  availableJobTitles: undefined,
  legalLastNameSearch: undefined,
  ssn: '',
  notes: '',
  newTitle: undefined,
  isReinstatement: undefined,
  effectiveDate: getDefaultEffectiveDate(),
  newRateUnit: undefined,
  rateUnitChange: undefined,
  titleRate: undefined,
  titleChangeRate: undefined,
}

const keysToClear: KeysToClear = {
  legalLastName: {
    keys: [
      'employee',
      'ssn',
      'effectiveDate',
      'location',
      'isReinstatement',
      'legalLastNameSearch',
    ],
    url: legalLastNameStep.url,
  },
  ssn: {
    keys: [
      'employee',
      'effectiveDate',
      'location',
      'isReinstatement',
      'legalLastNameSearch',
    ],
    url: ssnStep.url,
  },
  newTitle: {
    keys: ['newRateUnit', 'rateUnitChange', 'titleChangeRate', 'titleRate'],
    url: jobtTitleStep.url,
  },
  employee: {
    keys: ['location', 'isReinstatement'],
    url: employeeStep.url,
  },
  location: {
    keys: ['effectiveDate', 'isReinstatement'],
    url: locationStep().url,
  },
}

// Most of the flow pages are lazily imported (requires default)
// eslint-disable-next-line import/no-default-export
export function RehireReinstatement() {
  useTitle('Rehire/Reinstatement')
  const [isEmployeeSearchLoading, setIsEmployeeSearchLoading] = useState(false)
  const [isJobSearchLoading, setIsJobSearchLoading] = useState(false)
  const [steps, setSteps] = useState(calculateSteps(stepsInit, initialState))
  const [completed, setCompleted] = useState<Record<string, boolean>>({})
  const [data, dispatch] = useStateClearingReducer(
    keysToClear,
    steps,
    initialState,
    setCompleted
  )
  const clx: any = useClasses(styles)
  const contentStyle: any = useClasses(useContentStyle)

  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const loc = useLocation()
  const navigate = useNavigate()
  const getURL = useCallback((path?: string) => `${REHIRE_PATH}/${path}`, [])

  useEffect(() => {
    // If we navigate directly to a step in the flow but do not have a valid employee selected,
    // need to shortciruit back to first step.
    if (
      (!loc.pathname.includes(legalLastNameStep.url) && !data.legalLastName) ||
      loc.pathname === REHIRE_PATH
    ) {
      navigate(getURL(legalLastNameStep.url), { replace: true })
    }
  }, [loc.pathname, getURL, navigate, data.legalLastName])

  // this useffect will calculate number steps when the data changes
  // now based on employee and lastdayworked number of steps are changed
  useEffect(() => {
    const newSteps = calculateSteps(stepsInit, data)
    setSteps(newSteps)
  }, [data])

  // "source of truth" is the url, but need number value for Stepper
  const [step, setStep] = useState(0)
  useEffect(() => {
    setStep(steps.findIndex((val) => loc.pathname.includes(val.url)))
  }, [loc.pathname, steps])
  // leaving it to the logic below to determine whether to show back/next buttons instead of handling index out of bounds here
  const getNextPage = useCallback(
    () => getURL(steps[step + 1].url),
    [step, steps, getURL]
  )
  const getPrevPage = useCallback(
    () => getURL(steps[step - 1].url),
    [step, steps, getURL]
  )

  /**
   * Every time user goes next, mark that step as completed. This will allow
   * users to visit previously completed steps.
   */
  const handleNext = () => {
    handleNextStep(completed, setCompleted, steps, step, navigate, getNextPage)
  }

  const employeeSearch = useCallback(async () => {
    if (!data.ssn || !data.legalLastName) return
    setIsEmployeeSearchLoading(true)
    handleNextStep(completed, setCompleted, steps, step, navigate, getNextPage)

    const searchEmployeeData = await searchEmployee(
      '',
      [PositionStatus.Terminated],
      EmployeeRecordRequestType.Rehire,
      null,
      null,
      data.ssn,
      data.legalLastName
    )
    dispatch({ legalLastNameSearch: searchEmployeeData.data })
    setIsEmployeeSearchLoading(false)
  }, [
    completed,
    data.legalLastName,
    data.ssn,
    dispatch,
    getNextPage,
    navigate,
    step,
    steps,
  ])

  const jobtTitleSearch = useCallback(async () => {
    setIsJobSearchLoading(true)
    handleNextStep(completed, setCompleted, steps, step, navigate, getNextPage)

    const jobTitleData = await getJobTitles(
      data.employee?.id,
      data.location?.companyCode
    )
    dispatch({ availableJobTitles: jobTitleData.data })
    setIsJobSearchLoading(false)
  }, [
    completed,
    data.employee?.id,
    data.location?.companyCode,
    dispatch,
    getNextPage,
    navigate,
    step,
    steps,
  ])

  const { biweeklyAmount } = useMemo(() => {
    const employee = data.employee
    const newJobTitleRateCode = data.newTitle?.jobTitleRateCode
    const rateUnitChange = data.rateUnitChange

    let jobTitleRateUnit = employee?.jobTitleRateCode
    if (jobTitleRateUnit === JobTitleRateUnit.BOTH) {
      jobTitleRateUnit = employee?.rateUnit
    }

    let biweeklyAmount = 0

    if (
      (jobTitleRateUnit !== newJobTitleRateCode &&
        rateUnitChange === undefined) ||
      rateUnitChange
    ) {
      biweeklyAmount = convertRateToBiweeklyAmount(
        employee?.primaryRate ?? 0,
        (jobTitleRateUnit as JobTitleRateUnit) ?? RateUnit.HOURLY
      )
    } else {
      biweeklyAmount = employee?.primaryRate ?? 0
    }

    return { biweeklyAmount: round(biweeklyAmount, 2) }
  }, [data.employee, data.newTitle?.jobTitleRateCode, data.rateUnitChange])

  const SsnStep = useMemo(() => {
    const digitCount = (data.ssn.match(/\d/g) || []).length
    const fullSsnEntered = digitCount === 9
    let ssnIsValid = false

    if (fullSsnEntered) {
      // Disallows 000, 666, 900-999 in the first segment, 00 in the second, and 0000 in the third
      const ssnRegex = /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/

      if (ssnRegex.test(data.ssn)) {
        const ssnDigitsOnly = data.ssn.replace(/-/g, '')

        // Disallows all identical digits
        ssnIsValid = !/(.)\1{8}/.test(ssnDigitsOnly)
      }
    }
    const CustomInputMaskComponent = React.forwardRef<HTMLInputElement, any>(
      (props, ref) => {
        const { ...other } = props
        return (
          <InputMask
            {...other}
            ref={ref}
            mask="___-__-____"
            replacement={{ _: /\d/ }}
            showMask={true}
            value={data.ssn}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({ ssn: e.target.value })
            }}
          />
        )
      }
    )
    CustomInputMaskComponent.displayName = 'CustomInputMaskComponent'

    return (
      <>
        <TextField
          id="ssn"
          label="SSN"
          placeholder="SSN"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          autoFocus
          error={fullSsnEntered && !ssnIsValid}
          helperText={fullSsnEntered && !ssnIsValid ? 'SSN is invalid' : ''}
          InputProps={{
            inputComponent: CustomInputMaskComponent,
          }}
        />
        <StepButtons
          disabled={!ssnIsValid}
          handlePrev={() => navigate(getPrevPage())}
          handleNext={employeeSearch}
        />
      </>
    )
  }, [data.ssn, dispatch, employeeSearch, getPrevPage, navigate])

  const { roles } = useUser()
  if (!roles.canViewCreateRehire) {
    return <NoRoleAccess roleName={'Employment Change - Can Create Rehire'} />
  }
  return (
    <StepperWorkflow
      activeStep={step}
      nonLinear={data.employee !== undefined}
      stepperChildren={steps.map((stepInfo, idx) => {
        const isCompleted = completed[steps[idx].url]
        return (
          <Step key={idx}>
            {/* Only allow completed steps to be selected */}
            <StepButton
              disabled={step < idx && !isCompleted}
              onClick={() => navigate(getURL(steps[idx].url))}
            >
              <StepLabel>{matches ? stepInfo.stepLabel : ''}</StepLabel>
            </StepButton>
          </Step>
        )
      })}
    >
      <Box className={contentStyle.content}>
        <Typography variant="h4" gutterBottom>
          Rehire/Reinstatement
        </Typography>
        <Card className={clx.root}>
          <CardHeader title={steps[step]?.cardLabel ?? ''} />
          <CardContent>
            <React.Suspense fallback={<CircularProgress />}>
              <Routes>
                <Route
                  path={legalLastNameStep.url}
                  element={
                    <LegalLastName
                      data={data}
                      setData={(value) => dispatch({ legalLastName: value })}
                      handleNext={handleNext}
                      handlePrevious={() => navigate('/')}
                    />
                  }
                />
                <Route path={ssnStep.url} element={SsnStep} />
                <Route
                  path={employeeStep.url}
                  element={
                    <>
                      {isEmployeeSearchLoading ? (
                        <CircularProgress size="3rem" />
                      ) : data.legalLastNameSearch &&
                        data.legalLastNameSearch.length === 0 ? (
                        <>
                          We cannot find a match for this employee. If you are
                          sure you have entered the information correctly, then
                          you can make an offer to the candidate.
                          <StepButtons
                            disabled={false}
                            handlePrev={() => navigate(getPrevPage())}
                            handleNext={() => navigate('/')}
                            nextText={'Return to homepage'}
                          />
                        </>
                      ) : (
                        <>
                          <SelectEmployee
                            data={data}
                            setData={(value) => dispatch({ employee: value })}
                            employeeRecordRequestType={
                              EmployeeRecordRequestType.Rehire
                            }
                            statuses={[PositionStatus.Terminated]}
                            isPrimaryPosition={null}
                            perPage={10}
                            rehireSSN={data.ssn}
                            name={data.legalLastName}
                            handlePrev={() => navigate(getPrevPage())}
                          />
                          <BlockExistingRequest
                            data={data}
                            handleNext={handleNext}
                            navigate={navigate}
                            employeeRecordRequestType={
                              EmployeeRecordRequestType.Rehire
                            }
                            handlePrev={() => navigate(getPrevPage())}
                          />
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path={effectiveDateStep.url}
                  element={
                    <>
                      <EffectiveDate
                        data={{
                          ...data,
                          fiscalWeekStartDate:
                            data.employee?.fiscalWeekStartDate,
                          dateRange: data.employee?.dateRange,
                        }}
                        setData={(value) => dispatch({ effectiveDate: value })}
                        restrictToFiscalWeekStartDate
                      />
                      <StepButtons
                        disabled={data.effectiveDate === null}
                        handlePrev={() => navigate(getPrevPage())}
                        handleNext={handleNext}
                      />
                    </>
                  }
                />
                <Route
                  path={eligibilityStep.url}
                  element={
                    <>
                      {isEmployeeSearchLoading ? (
                        <CircularProgress size="3rem" />
                      ) : (
                        <RehireReinstatementEligibility
                          setData={(value: any) =>
                            dispatch({
                              isReinstatement: value,
                            })
                          }
                          data={data}
                          handleNext={jobtTitleSearch}
                          handlePrev={() => navigate(getPrevPage())}
                          navigate={navigate}
                        />
                      )}
                    </>
                  }
                />
                <Route
                  path={locationStep().url}
                  element={
                    <>
                      <SelectLocation
                        data={data}
                        setData={(value) => {
                          dispatch({
                            location: value,
                          })
                        }}
                      />
                      <StepButtons
                        disabled={!data.location}
                        handlePrev={() => navigate(getPrevPage())}
                        handleNext={handleNext}
                      />
                    </>
                  }
                />
                <Route
                  path={jobtTitleStep.url}
                  element={
                    <>
                      {isJobSearchLoading ? (
                        <CircularProgress size="3rem" />
                      ) : (
                        <>
                          <Autocomplete<JobTitle>
                            fullWidth
                            id="select-job-title"
                            options={data.availableJobTitles ?? []}
                            getOptionLabel={(opt: JobTitle) =>
                              `${opt.code} - ${opt.descriptionEnglish}`
                            }
                            openOnFocus
                            autoHighlight
                            onChange={(_, value) =>
                              dispatch({
                                newTitle: value as JobTitle,
                              })
                            }
                            value={data.newTitle}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Available titles"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                          <StepButtons
                            disabled={!data.newTitle}
                            handlePrev={() => navigate(getPrevPage())}
                            handleNext={handleNext}
                          />
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path={jobTitleRateTypeStep.url}
                  element={
                    <>
                      <SpecifyRateTypeAsk
                        data={{
                          currentRate: data.employee?.primaryRate ?? 0,
                          type: data.employee?.rateUnit ?? RateUnit.HOURLY,
                          rate: data.titleRate ?? biweeklyAmount,
                          changeRate: data.titleChangeRate,
                          currentJobTitleRateCode:
                            data.employee?.jobTitleRateCode,
                          newJobTitleRateCode: data.newTitle?.jobTitleRateCode,
                          rateUnit: data.employee?.rateUnit,
                          rateUnitChange: data.rateUnitChange,
                        }}
                        setData={(value) =>
                          dispatch({
                            newRateUnit: value?.newRateUnit,
                            rateUnitChange: value?.rateUnitChange,
                          })
                        }
                      />
                      <StepButtons
                        disabled={data.newRateUnit === undefined}
                        handlePrev={() => navigate(getPrevPage())}
                        handleNext={handleNext}
                      />
                    </>
                  }
                />
                <Route
                  path={jobTitleRateStep.url}
                  element={
                    <>
                      <SpecifyRateAsk
                        data={{
                          currentRate: data.employee?.primaryRate ?? 0,
                          type: data.employee?.rateUnit ?? RateUnit.HOURLY,
                          rate: data.titleRate ?? biweeklyAmount,
                          changeRate: data.titleChangeRate,
                          currentJobTitleRateCode:
                            data.employee?.jobTitleRateCode,
                          newJobTitleRateCode: data.newTitle?.jobTitleRateCode,
                          rateUnit: data.employee?.rateUnit,
                          rateUnitChange: data.rateUnitChange,
                        }}
                        setData={(val) =>
                          dispatch({
                            titleRate: val?.rate,
                            titleChangeRate: val?.changeRate,
                            newRateUnit: val?.newRateUnit,
                          })
                        }
                      />
                      <StepButtons
                        disabled={
                          data.titleChangeRate === undefined ||
                          (data.titleChangeRate === true &&
                            data.titleRate === biweeklyAmount)
                        }
                        handlePrev={() => navigate(getPrevPage())}
                        handleNext={handleNext}
                      />
                    </>
                  }
                />
                <Route
                  path={reasonStep.url}
                  element={
                    <>
                      <Notes
                        data={data}
                        setData={(value) => dispatch({ notes: value })}
                        label="Add reason"
                        placeholder="This employee should be rehired because..."
                      />
                      <StepButtons
                        disabled={data.notes.length > MAXIMUM_NOTES_LENGTH}
                        handlePrev={() => navigate(getPrevPage())}
                        handleNext={handleNext}
                      />
                    </>
                  }
                />
                <Route
                  path={reviewStep.url}
                  element={<ReviewRehire data={data} />}
                />
              </Routes>
            </React.Suspense>
          </CardContent>
        </Card>
      </Box>
    </StepperWorkflow>
  )
}
