import { Reason } from 'api'
import { BaseRequest } from 'types/BaseRequest'
import { BaseState } from 'types/BaseState'
import { Employee } from 'types/Employee'

export interface LeaveOfAbsenceRequest extends BaseRequest {
  employeeId: number
  lastDayWorked: string
  effectiveDate: string
  returnDate: string
  reasonId: number
  hasLeaveOfAbsenceDocument: boolean
  replacementEmployeeId?: number
}

export interface LeaveOfAbsenceState extends BaseState {
  lastDayWorked: Date | null
  effectiveDate: Date | null
  returnDate: Date | null
  reasonCode?: Reason
  replacement?: Employee
  hasLeaveOfAbsenceDocument: string
}

export function readyToSubmit(data: LeaveOfAbsenceState) {
  return (
    typeof data.employee !== 'undefined' &&
    data.effectiveDate !== null &&
    data.returnDate !== null &&
    typeof data.reasonCode !== 'undefined'
  ) // Notes allowed to be undefined
}

export enum hasLeaveOfAbsenceDocumentKeys {
  YES = 'YES',
  NO = 'NO',
}
