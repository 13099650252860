import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ReasonCodeDetails } from 'components/ReasonCodeDetails'
import StepperWorkflow from 'components/StepperWorkflow'
import {
  effectiveDateStep,
  getDefaultEffectiveDate,
} from 'components/steps/EffectiveDate'
import { lastDayWorkedStep } from 'components/steps/LastDayWorked'
import Notes, { notesStep } from 'components/steps/Notes'
import PendingRequests, {
  pendingRequestsStep,
} from 'components/steps/PendingRequests'
import { reasonStep } from 'components/steps/SelectReason'
import { selectReplacementStep } from 'components/steps/SelectReplacement'
import { workedDaysStep } from 'components/steps/WorkedDays'
import { reviewTerminationStep } from 'components/steps/review/ReviewTermination'
import { useUser } from 'context/Authenticate'
import dayjs from 'dayjs'
import { useClasses } from 'hooks/useClasses'
import {
  KeysToClear,
  useStateClearingReducer,
} from 'hooks/useStateClearingReducer'
import { useTitle } from 'hooks/useTitle'
import { TERMINATION_PATH } from 'link-paths'
import { handleNextStep } from 'pages/Home'
import NoRoleAccess from 'pages/NoRoleAccess'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { theme, useContentStyle } from 'theme'
import { Employee } from 'types/Employee'
import { PositionStatus } from 'types/PositionStatus'
import { RateUnit } from 'types/RateUnit'
import { EmployeeRecordRequestType } from 'types/StatusChangeResponse'
import { StepInfo } from 'types/StepProps'
import { TerminationChangeState } from 'types/TerminationChangeRequest'
import { MAXIMUM_NOTES_LENGTH } from '../components/steps/Notes'
const styles = {
  root: {
    minWidth: 450,
    minHeight: 200,
    maxWidth: 800,
  },
}

const BlockExistingRequest = React.lazy(
  () => import('components/steps/BlockExistingRequest')
)
const SelectEmployee = React.lazy(
  () => import('components/steps/SelectEmployee')
)
const EffectiveDate = React.lazy(() => import('components/steps/EffectiveDate'))
const LastDayWorked = React.lazy(() => import('components/steps/LastDayWorked'))
const WorkedDays = React.lazy(() => import('components/steps/WorkedDays'))
const SelectReplacement = React.lazy(
  () => import('components/steps/SelectReplacement')
)
const SelectReason = React.lazy(() => import('components/steps/SelectReason'))
const ReviewTermination = React.lazy(
  () => import('components/steps/review/ReviewTermination')
)
const employeeStep: StepInfo = {
  stepLabel: 'Employee',
  cardLabel: 'Which employee are you terminating?',
  url: 'employee',
}

type ConditionalSteps = (
  | StepInfo
  | ((employee: Employee | undefined) => StepInfo | null)
  | ((lastDayWorked: Date | null | undefined) => StepInfo | null)
)[]
// Starting set of steps (others are added conditionally based on data from employee details)
const stepsInit: ConditionalSteps = [
  employeeStep,
  (data) =>
    (data?.employee?.employeePendingActionsInAdp?.length ?? 0) > 0
      ? pendingRequestsStep
      : null,
  reasonStep,
  lastDayWorkedStep,
  (data) =>
    dayjs(data.lastDayWorked as Date).isAfter(dayjs(new Date()), 'day') ||
    dayjs(data.lastDayWorked as Date).isSame(dayjs(new Date()), 'day')
      ? workedDaysStep(data.employee?.rateUnit)
      : null,
  effectiveDateStep,
  (data) =>
    data.employee?.hasReportTos
      ? selectReplacementStep(data.employee?.employeeName)
      : null,
  notesStep,
  reviewTerminationStep,
]

function calculateSteps(steps: ConditionalSteps, state: any): StepInfo[] {
  return steps.reduce((prev, curr) => {
    if (typeof curr === 'function') {
      const result = curr(state)
      if (result) {
        prev.push(result)
      }
    } else {
      prev.push(curr)
    }
    return prev
  }, [] as StepInfo[])
}

const initialState: TerminationChangeState = {
  employee: undefined,
  effectiveDate: getDefaultEffectiveDate(),
  lastDayWorked: getDefaultEffectiveDate(),
  reasonCode: undefined,
  notes: '',
  workedDays: undefined,
  userAcknowledgedPendingRequestsInAdp: false,
}

const keysToClear: KeysToClear = {
  employee: {
    keys: [
      'userAcknowledgedPendingRequestsInAdp',
      'reasonCode',
      'lastDayWorked',
      'workedDays',
      'effectiveDate',
      'replacement',
    ],
    url: employeeStep.url,
  },
  reasonCode: {
    keys: ['lastDayWorked', 'workedDays', 'effectiveDate', 'replacement'],
    url: reasonStep.url,
  },
  lastDayWorked: {
    keys: ['workedDays', 'effectiveDate', 'replacement'],
    url: lastDayWorkedStep.url,
  },
  workedDays: {
    keys: ['effectiveDate', 'replacement'],
    // this normally takes rateUnit as an argument,
    //but that only affects the title.
    //We only care about the URL here.
    url: workedDaysStep().url,
  },
  effectiveDate: {
    keys: ['replacement'],
    url: effectiveDateStep.url,
  },
  replacement: {
    keys: ['replacement'],
    url: selectReplacementStep().url,
  },
}

// Most of the flow pages are lazily imported (requires default)
// eslint-disable-next-line import/no-default-export
export function Termination() {
  useTitle('Termination')
  const [steps, setSteps] = useState(calculateSteps(stepsInit, initialState))
  const [completed, setCompleted] = useState<Record<string, boolean>>({})

  const [data, dispatch] = useStateClearingReducer(
    keysToClear,
    steps,
    initialState,
    setCompleted
  )

  const clx: any = useClasses(styles)
  const contentStyle: any = useClasses(useContentStyle)
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const loc = useLocation()
  const navigate = useNavigate()
  const getURL = useCallback(
    (path: string) => `${TERMINATION_PATH}/${path}`,
    []
  )

  useEffect(() => {
    // If we navigate directly to a step in the flow but do not have a valid employee selected,
    // need to shortciruit back to first step.
    if (
      (!loc.pathname.includes(employeeStep.url) && !data.employee) ||
      loc.pathname === TERMINATION_PATH
    ) {
      navigate(getURL(employeeStep.url), { replace: true })
    }
  }, [data.employee, loc.pathname, getURL, navigate])

  // this useffect will calculate number steps when the data changes
  // now based on employee and lastdayworked number of steps are changed
  useEffect(() => {
    const newSteps = calculateSteps(stepsInit, data)
    setSteps(newSteps)
  }, [data])

  // "source of truth" is the url, but need number value for Stepper
  const [step, setStep] = useState(0)
  useEffect(() => {
    setStep(steps.findIndex((val) => loc.pathname.includes(val.url)))
  }, [loc.pathname, steps])
  // leaving it to the logic below to determine whether to show back/next buttons instead of handling index out of bounds here
  const getNextPage = useCallback(
    () => getURL(steps[step + 1].url),
    [step, steps, getURL]
  )
  const getPrevPage = useCallback(
    () => getURL(steps[step - 1].url),
    [step, steps, getURL]
  )
  /**
   * Every time user goes next, mark that step as completed. This will allow
   * users to visit previously completed steps.
   */
  const handleNext = () => {
    handleNextStep(completed, setCompleted, steps, step, navigate, getNextPage)
  }

  const disableHourlyWorkedDayNext = useMemo(() => {
    if (data.employee?.rateUnit === RateUnit.HOURLY) {
      return (
        data.workedDays === undefined ||
        data.workedDays.some(
          (day) =>
            day.isLastDay === true && !day.regularHours && !day.overTimeHours
        )
      )
    }
    return false
  }, [data.workedDays, data.employee?.rateUnit])

  const { roles } = useUser()

  const user = useUser()
  if (!user.roles.canCreateEditAndBasic) {
    return (
      <NoRoleAccess
        roleName={'Employment Change - Can Create and Edit Basic'}
      />
    )
  }
  return (
    <StepperWorkflow
      activeStep={step}
      nonLinear={data.employee !== undefined}
      stepperChildren={steps.map((stepInfo, idx) => {
        const isCompleted = completed[steps[idx].url]
        return (
          <Step key={idx}>
            {/* Only allow completed steps to be selected */}
            <StepButton
              disabled={step < idx && !isCompleted}
              onClick={() => navigate(getURL(steps[idx].url))}
              // completed={isCompleted}
            >
              <StepLabel>{matches ? stepInfo.stepLabel : ''}</StepLabel>
            </StepButton>
          </Step>
        )
      })}
    >
      <Box className={contentStyle.content}>
        <Typography variant="h4" gutterBottom>
          Termination
        </Typography>
        <Card className={clx.root}>
          <CardHeader
            title={
              steps[step]?.url !== 'last-day-worked' ? (
                steps[step]?.cardLabel ?? ''
              ) : (
                <>
                  <Typography
                    variant="h5"
                    style={{
                      display: 'inline',
                      color: theme.palette.primary.light,
                    }}
                  >
                    {data.employee?.employeeName}
                    {"'s "}
                  </Typography>
                  {steps[step]?.cardLabel ?? ''}
                </>
              )
            }
          />
          <CardContent>
            <React.Suspense fallback={<CircularProgress />}>
              <Routes>
                <Route
                  path={employeeStep.url}
                  element={
                    <>
                      <SelectEmployee
                        data={data}
                        setData={(value) => dispatch({ employee: value })}
                        statuses={[PositionStatus.Active, PositionStatus.Leave]}
                        employeeRecordRequestType={
                          EmployeeRecordRequestType.Termination
                        }
                        handlePrev={() => navigate('/')}
                      />
                      <BlockExistingRequest
                        data={data}
                        handleNext={handleNext}
                        navigate={navigate}
                        employeeRecordRequestType={
                          EmployeeRecordRequestType.Termination
                        }
                      />
                    </>
                  }
                />
                <Route
                  path={pendingRequestsStep.url}
                  element={
                    <>
                      <PendingRequests
                        data={data}
                        setData={(value) =>
                          dispatch({
                            userAcknowledgedPendingRequestsInAdp:
                              value.userAcknowledgedPendingRequestsInAdp,
                          })
                        }
                        handleNext={handleNext}
                        navigate={navigate}
                        getPrevPage={getPrevPage}
                      />
                    </>
                  }
                />
                <Route
                  path={effectiveDateStep.url}
                  element={
                    <>
                      <EffectiveDate
                        data={{
                          ...data,
                          fiscalWeekStartDate:
                            data.employee?.fiscalWeekStartDate,
                        }}
                        setData={(value) => dispatch({ effectiveDate: value })}
                        DatePickerProps={{
                          minDate: dayjs(data.lastDayWorked),
                          maxDate: dayjs(data.lastDayWorked ?? dayjs()).add(
                            roles.canSelectExtendedEffectiveDateRanges
                              ? 2000
                              : 28,
                            'day'
                          ),
                        }}
                      />
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={data.effectiveDate === null}
                          data-testid="proceed-in-workflow"
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={lastDayWorkedStep.url}
                  element={
                    <>
                      <Box width="100%" display="flex" justifyContent="center">
                        <Box width={450}>
                          <LastDayWorked
                            data={{
                              ...data,
                              dateRange: data.employee?.dateRange,
                            }}
                            setData={(value) =>
                              dispatch({
                                lastDayWorked: value,
                                effectiveDate: value, // default effective date to last day worked whenever last day worked changed
                                workedDays: undefined,
                              })
                            }
                          />
                        </Box>
                      </Box>
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={data.lastDayWorked === null}
                          data-testid="proceed-in-workflow"
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={reasonStep.url}
                  element={
                    <>
                      <SelectReason
                        data={data}
                        setData={(value) =>
                          dispatch({
                            reasonCode: value,
                          })
                        }
                        type="termination"
                      />
                      &nbsp;
                      {data.reasonCode && (
                        <ReasonCodeDetails reasonCode={data.reasonCode} />
                      )}
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={!data.reasonCode}
                          data-testid="proceed-in-workflow"
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />

                <Route
                  path={`${workedDaysStep(data.employee?.rateUnit).url}`}
                  element={
                    <>
                      <WorkedDays
                        data={data}
                        setData={(value) => dispatch({ workedDays: value })}
                      />

                      {disableHourlyWorkedDayNext &&
                        data.employee?.rateUnit === RateUnit.HOURLY && (
                          <Typography
                            style={{
                              fontWeight: 600,
                              margin: 20,
                            }}
                          >
                            * Hours for Last Day Worked (
                            {data?.lastDayWorked &&
                              dayjs(data?.lastDayWorked).format('MM/DD/YYYY')}
                            ) required and must be greater than Zero.
                          </Typography>
                        )}
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={
                            data.employee?.rateUnit === RateUnit.HOURLY
                              ? disableHourlyWorkedDayNext
                              : false
                          }
                          data-testid="proceed-in-workflow"
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={`${
                    selectReplacementStep(data.employee?.employeeName).url
                  }`}
                  element={
                    <>
                      <SelectReplacement
                        data={data}
                        setData={(value) =>
                          dispatch({
                            replacement: value,
                          })
                        }
                      />
                      <Box my={3} />

                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={!data.replacement}
                          data-testid="proceed-in-workflow"
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={notesStep.url}
                  element={
                    <>
                      <Notes
                        data={data}
                        setData={(value) => dispatch({ notes: value })}
                      />
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={data.notes.length > MAXIMUM_NOTES_LENGTH}
                          onClick={handleNext}
                          data-testid="proceed-in-workflow"
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={reviewTerminationStep.url}
                  element={
                    <>
                      <ReviewTermination data={data} />
                    </>
                  }
                />
              </Routes>
            </React.Suspense>
          </CardContent>
        </Card>
      </Box>
    </StepperWorkflow>
  )
}
